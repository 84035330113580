import CheckoutQuery from 'Query/Checkout.query';
import { isSignedIn } from 'Util/Auth';
import { getCartId } from 'Util/Cart';
import { fetchMutation } from 'Util/Request';

const savePaymentMethod = async (instance, code) => {
    const cart_id = !isSignedIn() ? getCartId() : '';

    await fetchMutation(CheckoutQuery.getSetPaymentMethodOnCartMutation({
        cart_id,
        payment_method: {
            code
        }
    }));

    instance.props.onSavePaymentMethod(code);
};

const onPaymentMethodSelect = (args, callback, instance) => {
    callback(...args);
    const [code] = args;
    savePaymentMethod(instance, code);
};

export default {
    'Component/CheckoutBilling/Container': {
        'member-function': {
            onPaymentMethodSelect
        }
    }
};
