import { isSignedIn } from 'Util/Auth';
import { deleteCartId, getCartId } from 'Util/Cart';
import CheckoutQuery from 'Query/Checkout.query';
import { fetchMutation } from 'Util/Request';
import BrowserDatabase from 'Util/BrowserDatabase';
import { ONE_MONTH_IN_SECONDS } from 'Util/Request/QueryDispatcher';
import { updateEmailAvailable } from 'Store/Checkout/Checkout.action';
import {
    DETAILS_STEP,
    PAYMENT_TOTALS
} from 'Route/Checkout/Checkout.config';
import history from 'Util/History';

import {
    paymentSuccessHandler,
    paymentFailureHandler,
    processFailureHandler,
    processRestoreCartHandler,
    getPfcConfig,
    fallbackToPaymentPage
} from '@insign/scandipwa-postfinancecheckout-payment/src/helper/Checkout.helper';

import { RESTORE_CART_STEP } from '@insign/scandipwa-postfinancecheckout-payment/src/config/Checkout.config';
import { CART_TAB } from 'Component/NavigationTabs/NavigationTabs.config';

const mapDispatchToProps = (args, callback, instance) => {
    const [dispatch] = args;

    return {
        ...callback(...args),
        setIsEmailAvailable: (isEmailAvailable) => dispatch(updateEmailAvailable(isEmailAvailable))
    };
};

// eslint-disable-next-line consistent-return
const savePaymentMethodAndPlaceOrder = async (args, callback, instance) => {
    const [paymentInformation] = args;

    const { paymentMethod: { code, additional_data } } = paymentInformation;
    const guest_cart_id = getCartId();

    const paymentMethod = instance.state.pfc_payments_config.payment.find((p) => p.paymentMethodCode === code);

    // check if it is a postfinance checkout payment method
    if (!paymentMethod) {
        BrowserDatabase.setItem('default', 'payment_provider', ONE_MONTH_IN_SECONDS);
        const retVal = await callback(...args);
        return retVal;
    }

    // if yes, do this custom logic
    try {
        // Just like the original function, we set the payment method
        await fetchMutation(CheckoutQuery.getSetPaymentMethodOnCartMutation({
            cart_id: guest_cart_id,
            payment_method: {
                code,
                [code]: additional_data
            }
        }));

        // and place the order
        const orderData = await fetchMutation(CheckoutQuery.getPlaceOrderMutation(guest_cart_id));
        const { placeOrder: { order: { order_id } } } = orderData;

        const {
            billingAddress,
            shippingAddress,
            email
        } = instance.state;
        const {
            isEmailAvailable
        } = instance.props;

        // set some data to local storage to show on success page
        BrowserDatabase.setItem('pfc_payment', 'payment_provider', ONE_MONTH_IN_SECONDS);
        BrowserDatabase.setItem({
 order_id, billingAddress, shippingAddress, isEmailAvailable, email 
}, 'pfc_payment_order_data', ONE_MONTH_IN_SECONDS);

        if (typeof window.LightboxCheckoutHandler !== 'undefined') {
            window.LightboxCheckoutHandler.startPayment(paymentMethod.configurationId, () => {
                fallbackToPaymentPage(instance, paymentMethod);
            });
        } else {
            fallbackToPaymentPage(instance, paymentMethod);
        }
    } catch (e) {
        instance._handleError(e);
    }
};

const __construct = (args, callback, instance) => {
    const [props] = args;
    const {
        toggleBreadcrumbs
    } = props;

    const inst = instance;

    const payment_provider = BrowserDatabase.getItem('payment_provider');

    // check if we should show success page for postfinance checkout
    if (payment_provider === 'pfc_payment') {
        toggleBreadcrumbs(false);

        switch (history.location.pathname) {
            case '/checkout/payment/success':
                paymentSuccessHandler(args, callback, inst);
                break;
            case '/checkout/payment/failure':
                paymentFailureHandler(args, callback, inst);
                break;
            case '/checkout/process/failure':
                processFailureHandler(args, callback, inst);
                break;
            case '/checkout/process/restoreCart':
                processRestoreCartHandler(args, callback, inst);
                break;
            default:
                callback(...args);
                inst.state.pfc_payments_config = {};
                break;
        }
    } else {
        callback(...args);
        inst.state.pfc_payments_config = {};
    }
};

const componentDidMount = (args, callback, instance) => {
    const payment_provider = BrowserDatabase.getItem('payment_provider');
    if ((instance.state.checkoutStep !== DETAILS_STEP && instance.state.checkoutStep !== RESTORE_CART_STEP) || payment_provider !== 'pfc_payment') {
        callback(...args);
        getPfcConfig(instance);
    }
    if (payment_provider === 'pfc_payment' && instance.state.checkoutStep === DETAILS_STEP) {
        const {
            setIsEmailAvailable,
            resetCart,
            resetGuestCart,
            setNavigationState
        } = instance.props;

        if (Object.prototype.hasOwnProperty.call(instance.state, 'isEmailAvailable')) {
            setIsEmailAvailable(instance.state.isEmailAvailable);
        }

        deleteCartId();
        BrowserDatabase.deleteItem(PAYMENT_TOTALS);
    
        if (isSignedIn()) {
            resetCart();
        } else {
            resetGuestCart();
        }
    
        setNavigationState({
            name: CART_TAB
        });
    }
    BrowserDatabase.setItem('default', 'payment_provider', ONE_MONTH_IN_SECONDS);
};

const containerProps = (args, callback, instance) => {
    const originalProps = callback(...args);
    if (instance.state.pfc_payments_config.payment) {
        originalProps.paymentMethods = originalProps.paymentMethods.map((pm) => {
            const paymentMethod = instance.state.pfc_payments_config.payment.find((p) => p.paymentMethodCode === pm.code);
            return {
                ...pm,
                ...paymentMethod
            };
        });
    }

    return originalProps;
};

export default {
    'Route/Checkout/Container': {
        'member-function': {
            savePaymentMethodAndPlaceOrder,
            __construct,
            componentDidMount,
            containerProps
        }
    },
    'Route/Checkout/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    }
};
