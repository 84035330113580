/* eslint-disable spaced-comment */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import {
    VALIDATION_INPUT_TYPE,
    VALIDATION_RULES as SOURCE_VALIDATION_RULES,
    VALIDATION_INPUT_TYPE_TEXT,
    VALIDATION_INPUT_TYPE_NUMBER,
    VALIDATION_INPUT_TYPE_INPUT,
    VALIDATION_MESSAGES as SOURCE_VALIDATION_MESSAGES
} from 'SourceUtil/Validator/Config';

export const VALIDATION_RULES = {
    ...SOURCE_VALIDATION_RULES,
    // Text
    [VALIDATION_INPUT_TYPE.alpha]: /^[a-zöäüëéèêàâôìïîûæ]+$/i,
    [VALIDATION_INPUT_TYPE.alphaSpace]: /^[a-zöäüëéèêàâôìïîûæ\s]+$/i
};

export const VALIDATION_MESSAGES = {
    ...SOURCE_VALIDATION_MESSAGES,
    [VALIDATION_INPUT_TYPE.alphaSpace]: __('Incorrect input!')
};

export {
    VALIDATION_INPUT_TYPE,
    VALIDATION_INPUT_TYPE_TEXT,
    VALIDATION_INPUT_TYPE_NUMBER,
    VALIDATION_INPUT_TYPE_INPUT
};
