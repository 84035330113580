import { isSignedIn } from 'Util/Auth';
import { getCartId } from 'Util/Cart';
import BrowserDatabase from 'Util/BrowserDatabase';
import { ONE_MONTH_IN_SECONDS } from 'Util/Request/QueryDispatcher';
import {
    DETAILS_STEP,
    SHIPPING_STEP,
    PAYMENT_TOTALS
} from 'Route/Checkout/Checkout.config';
import { fetchMutation, fetchQuery } from 'Util/Request';
import PFPQuery from '@insign/scandipwa-postfinancecheckout-payment/src/query/Postfinance.query';

import { RESTORE_CART_STEP, PFP_SCRIPT_ID } from '@insign/scandipwa-postfinancecheckout-payment/src/config/Checkout.config';

/** @namespace Insign/PostfinancePayment/Helper/paymentSuccessHandler */
export const paymentSuccessHandler = (args, originalConstructor, instance) => {
    const order_data = BrowserDatabase.getItem('pfc_payment_order_data');
    // remove order info from local storage
    BrowserDatabase.setItem({}, 'pfc_payment_order_data', ONE_MONTH_IN_SECONDS);
    const {
        order_id,
        billingAddress,
        isEmailAvailable,
        email
    } = order_data;
    const inst = instance;

    // do similar things like in setDetailsStep function
    inst.state = {
        isLoading: false,
        isDeliveryOptionsLoading: false,
        requestsSent: 0,
        paymentMethods: [],
        shippingMethods: [],
        shippingAddress: {},
        selectedShippingMethod: '',
        isGuestEmailSaved: true,
        isCreateUser: false,
        estimateAddress: {},
        isPickInStoreMethodSelected: false,

        checkoutStep: DETAILS_STEP,
        paymentTotals: BrowserDatabase.getItem(PAYMENT_TOTALS) || {},
        email,
        billingAddress,
        orderID: order_id,
        pfc_payments_config: {},
        isEmailAvailable
    };
};

/** @namespace Insign/PostfinancePayment/Helper/restoreCart */
export const restoreCart = async (props) => {
    const guest_cart_id = !isSignedIn() ? getCartId() : props.totals.id;
    await fetchMutation(PFPQuery.getRestoreCartMutation(guest_cart_id));
};

/** @namespace Insign/PostfinancePayment/Helper/getPfcConfig */
export const getPfcConfig = async (instance) => {
    const guest_cart_id = !isSignedIn() ? getCartId() : instance.props.totals.id;
    const pfpConfig = await fetchQuery(PFPQuery.getQuery(guest_cart_id));
    const {
        getPostFinancePaymentsConfig: {
            postfinancecheckout,
            payment
        }
    } = pfpConfig;

    instance.setState({ 
        pfc_payments_config: {
            postfinancecheckout,
            payment
        }
    });

    // Render Script
    const script = document.getElementById(PFP_SCRIPT_ID);
    if (script) {
        // remove old script
        script.parentNode.removeChild(script);
    }
    const pfpScript = document.createElement('script');
    pfpScript.setAttribute('id', PFP_SCRIPT_ID);
    pfpScript.setAttribute('src', postfinancecheckout.integrationMethod === 'lightbox' ? postfinancecheckout.lightboxUrl : postfinancecheckout.javascriptUrl);
    pfpScript.async = true;
    document.head.appendChild(pfpScript);
};

/** @namespace Insign/PostfinancePayment/Helper/defaultFailureHandler */
export const defaultFailureHandler = async (args, originalConstructor, instance) => {
    const [props] = args;
    const { showErrorNotification } = props;

    const order_data = BrowserDatabase.getItem('pfc_payment_order_data');
    // remove order info from local storage
    BrowserDatabase.setItem({}, 'pfc_payment_order_data', ONE_MONTH_IN_SECONDS);
    const {
        order_id,
        billingAddress,
        shippingAddress,
        email
    } = order_data;
    const inst = instance;

    showErrorNotification(__('Payment process was canceled!'));
    // originalConstructor(props);
    inst.state = {
        isLoading: false,
        isDeliveryOptionsLoading: false,
        requestsSent: 0,
        paymentMethods: [],
        shippingMethods: [],
        shippingAddress,
        selectedShippingMethod: '',
        isGuestEmailSaved: false,
        isCreateUser: false,
        estimateAddress: {},
        isPickInStoreMethodSelected: false,
        checkoutStep: RESTORE_CART_STEP,
        paymentTotals: BrowserDatabase.getItem(PAYMENT_TOTALS) || {},
        email,
        billingAddress,
        orderID: order_id,
        pfc_payments_config: {}
    };
    await restoreCart(props);
    await props.resetCart();

    BrowserDatabase.deleteItem(PAYMENT_TOTALS);

    // eslint-disable-next-line react/no-did-update-set-state
    instance.setState({
        checkoutStep: SHIPPING_STEP
    });
    getPfcConfig(instance);
};

/** @namespace Insign/PostfinancePayment/Helper/paymentFailureHandler */
export const paymentFailureHandler = async (args, originalConstructor, instance) => {
    defaultFailureHandler(args, originalConstructor, instance);
};

/** @namespace Insign/PostfinancePayment/Helper/processFailureHandler */
export const processFailureHandler = async (args, originalConstructor, instance) => {
    defaultFailureHandler(args, originalConstructor, instance);
};

/** @namespace Insign/PostfinancePayment/Helper/processRestoreCartHandler */
export const processRestoreCartHandler = async (args, originalConstructor, instance) => {
    defaultFailureHandler(args, originalConstructor, instance);
};

/** @namespace Insign/PostfinancePayment/Helper/fallbackToPaymentPage */
export const fallbackToPaymentPage = async (instance, paymentMethod) => {
    if (instance.state.pfc_payments_config?.postfinancecheckout?.paymentPageUrl) {
        window.location.replace(`${ instance.state.pfc_payments_config.postfinancecheckout.paymentPageUrl }&paymentMethodConfigurationId=${ paymentMethod.configurationId }`);
    } else {
        instance.props.showErrorNotification(__('Payment process could not be started!'));
        await restoreCart(instance.props);
        await instance.props.resetCart();
        BrowserDatabase.setItem('default', 'payment_provider', ONE_MONTH_IN_SECONDS);
    }
};
