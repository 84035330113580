import {
    ChevronIcon as SourceChevronIcon
} from 'SourceComponent/ChevronIcon/ChevronIcon.component';

import './ChevronIcon.style';

export class ChevronIconComponent extends SourceChevronIcon {
    render() {
        const { direction } = this.props;

        return (
            <svg
              block="ChevronIcon"
              mods={ { direction } }
              width="16"
              height="16"
              viewBox="0 0 320 512"
              fill="#333333"
              xmlns="http://www.w3.org/2000/svg"
            >
            { /* Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. */ }
                <path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" />
            </svg>
        );
    }
}

export default ChevronIconComponent;
